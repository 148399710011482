import React from 'react'
import {
    Button,
    Container,
    Grid,
    Header,
    List,
    ListItem,
    Segment,
} from 'semantic-ui-react'
import '../App.css';

function Partners() {
    return (
        <Container text>
            <Segment vertical>
            <Header as={"h2"} content="Partners of the Herd" />
            </Segment>
            <Segment raised>
                <Header as='h4' content="Many thanks to these folks who have helped me get started with their donations and support:" />
                <List relaxed>
                    <ListItem content="Mikell and Will Abney" />
                    <ListItem content="Katie and Phil Armentor" />
                    <ListItem content="Casey and Chuck Boettcher" />
                    <ListItem content="Michael Blumenthal" />
                    <ListItem content="Emily and Trey Burke" />
                    <ListItem content="Meredith and Greg Duncan" />
                    <ListItem content="Becca and James Edmiston" />
                    <ListItem content="Meg and Jeff Hamilton" />
                    <ListItem content="Cindy Heckard" />
                    <ListItem content="Loma and Bill Hobson" />
                    <ListItem content="Mary Ellen and Karl Klanke" />
                    <ListItem content="Collin Klenke" />
                    <ListItem content="Connie and Allan Klenke" />
                    <ListItem content="Erin and Greg Klenke" />
                    <ListItem content="Mary and Ken Klenke" />
                    <ListItem content="Lisa and Scott Kovalik" />
                    <ListItem content="Paddy Magliolo" />
                    <ListItem content="Leah and Brian McDonald" />
                    <ListItem content="Lindsay and Paul Laudadio" />
                    <ListItem content="Kathleen O'Connor" />
                    <ListItem content="Megan and Nick Pepitone" />
                    <ListItem content="Cole Rongey" />
                    <ListItem content="Leslie and Steve Shofner" />
                </List>
            </Segment>
        </Container>
    )
};

export default Partners;