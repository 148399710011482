import winnie from './WinnieTheMoo.jpg'
import pepiShof from './PepiShof.jpg'
import norman from './Norman.jpeg'
import tasty from './Tasty.jpeg'
import blumey from './Blumey.jpeg'
import olive from './Olive.jpeg'
import hobson from './Hobsons-Choice.jpg'
import ferdinand from './Ferdinand.jpeg'
import murky from './Murky.jpeg'
import steer1 from './Steer1.jpeg'
import tBone from './T-Bone.jpg'
import steer3 from './Steer3.jpeg'
import steer4 from './Steer4.jpeg'
import curlyQ from './Curly-Q.jpg'
import loblolly from './Loblolly.jpg'
import jamal from './Jamal.jpg'
import hoofHearted from './Hoof-Hearted.jpg'
import dakota from './Dakota.png'
import mootRockne from './Moot-Rockne.jpg'
import wildBill from './Wild-Bill.jpg'

export const cows = [
    {
        src: winnie,
        title: 'Cow #1: Winnie the Moo'
    },
    {
        src: pepiShof,
        title: 'Cow #2: Pepi Shof'
    },
    {
        src: norman,
        title: 'Cow #3: Norman'
    },
    {
        src: tasty,
        title: 'Cow #4: Tasty'
    },
    {
        src: blumey,
        title: 'Cow #5: Blumey'
    },
    {
        src: olive,
        title: 'Cow #6: Olive'
    },
    {
        src: hobson,
        title: 'Cow #7: Hobson\'s Choice'
    },
    {
        src: ferdinand,
        title: 'Bull: Ferdinand'
    },
    {
        src: murky,
        title: 'Heifer: Murky'
    },
    {
        src: steer1,
        title: 'Steer #1'
    },
    {
        src: tBone,
        title: 'Steer #2: T Bone'
    },
    {
        src: steer3,
        title: 'Steer #3'
    },
    {
        src: steer4,
        title: 'Steer #4'
    },
    {
        src: curlyQ,
        title: 'Steer #5: Curly Q'
    },
    {
        src: loblolly,
        title: 'Steer #6: Loblolly'
    },
    {
        src: jamal,
        title: 'Steer #7: Jamal'
    },
    {
        src: hoofHearted,
        title: 'Steer #8: Hoof Hearted'
    },
    {
        src: dakota,
        title: 'Steer #9: Dakota'
    },
    {
        src: mootRockne,
        title: 'Steer #10: Moot Rockne'
    },
    {
        src: wildBill,
        title: 'Steer #11: Wild Bill'
    },
]