import React from 'react'
import { Container,  Header,  List, ListItem, Segment, Icon } from 'semantic-ui-react'
import '../App.css';

function Newsletters() {
    return (
        <Container text style={{ 'padding-bottom': '40px' }}>
            <Segment vertical>
            <Header icon style={{display: 'block'}}>
                <Icon name='archive' />
                Newsletter Archive
            </Header>
            </Segment>
            <Segment raised>
                <Header as='h4' content="March 2024" />
                <List relaxed>
                    <ListItem>
                        <List.Icon name="linkify"/>
                        <List.Content>
                            <a href='https://mailchi.mp/7eacca6c8a20/catching-up-with-community-cattle'>
                            Fence
                            </a>
                        </List.Content>
                    </ListItem>
                </List>
            </Segment>
            <Segment raised>
                <Header as='h4' content="February 2024" />
                <List relaxed>
                    <ListItem>
                        <List.Icon name="linkify"/>
                        <List.Content>
                            <a href='https://mailchi.mp/eae91b171681/catching-up-with-community-cattle'>
                            Special deliveries
                            </a>
                        </List.Content>
                    </ListItem>
                </List>
            </Segment>
            <Segment raised>
                <Header as='h4' content="January 2024" />
                <List relaxed>
                    <ListItem>
                        <List.Icon name="linkify"/>
                        <List.Content>
                            <a href='https://mailchi.mp/399fb75f4a2e/catching-up-with-community-cattle'>
                            Mission accomplished, part 1
                            </a>
                        </List.Content>
                    </ListItem>
                </List>
            </Segment>
            <Segment raised>
                <Header as='h4' content="December 2023" />
                <List relaxed>
                    <ListItem>
                        <List.Icon name="linkify"/>
                        <List.Content>
                            <a href='https://mailchi.mp/284682c8fa55/catching-up-with-community-cattle'>
                            Short and sweet
                            </a>
                        </List.Content>
                    </ListItem>
                </List>
            </Segment>
            <Segment raised>
                <Header as='h4' content="November 2023" />
                <List relaxed>
                    <ListItem>
                        <List.Icon name="linkify"/>
                        <List.Content>
                            <a href='https://mailchi.mp/d343c8d354b2/catching-up-with-community-cattle'>
                            Adorable addition
                            </a>
                        </List.Content>
                    </ListItem>
                </List>
            </Segment>
            <Segment raised>
                <Header as='h4' content="October 2023" />
                <List relaxed>
                    <ListItem>
                        <List.Icon name="linkify"/>
                        <List.Content>
                            <a href='https://mailchi.mp/dc2dce07c80a/catching-up-with-community-cattle'>
                            The New Baby Edition
                            </a>
                        </List.Content>
                    </ListItem>
                </List>
            </Segment>
            <Segment raised>
                <Header as='h4' content="September 2023" />
                <List relaxed>
                    <ListItem>
                        <List.Icon name="linkify"/>
                        <List.Content>
                            <a href='https://mailchi.mp/53ec4eac9c58/catching-up-with-community-cattle'>
                            8 cringe-worthy moments at the vet
                            </a>
                        </List.Content>
                    </ListItem>
                </List>
            </Segment>
            <Segment raised>
                <Header as='h4' content="August 2023" />
                <List relaxed>
                    <ListItem>
                        <List.Icon name="linkify"/>
                        <List.Content>
                            <a href='https://mailchi.mp/88f8bc0a5397/catching-up-with-community-cattle?e=ce318f9cbb'>
                                Introducing Community Cattle
                            </a>
                        </List.Content>
                    </ListItem>
                </List>
            </Segment>
        </Container>
    )
};

export default Newsletters;